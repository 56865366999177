<template>
  <div class="container">
    <div class="header">
      <div class="user-info-box">
        <div class="user-info">
          <van-image class="avatar" :src="user.avatar" />

          <div class="nickname-box">
            <div class="nickname">{{ user.name }}</div>
            <div class="steam-id">{{ player && player.steam_account ? 'SteamID:' + player.steam_account : '' }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="menu-card">
        <div class="menu">
          <router-link class="menu-link" :to="{ name: 'WelfareCenter' }">
            <van-icon name="gift-o" />
            <div class="menu-label">福利中心</div>
          </router-link>
        </div>

        <div class="menu">
          <router-link class="menu-link" :to="{ name: 'GameReservation' }">
            <van-icon name="new-o" />
            <div class="menu-label">新游推荐</div>
          </router-link>
        </div>

<!--        <div class="menu">-->
<!--          <router-link class="menu-link" :to="{ name: 'Feedback' }">-->
<!--            <van-icon name="question-o" />-->
<!--            <div class="menu-label">问题反馈</div>-->
<!--          </router-link>-->
<!--        </div>-->
<!--        <div class="menu">-->
<!--          <router-link class="menu-link" :to="{ name: 'FeedbackList' }">-->
<!--            <van-icon name="records" />-->
<!--            <div class="menu-label">问题反馈记录</div>-->
<!--          </router-link>-->
<!--        </div>-->
<!--        <div class="menu">-->
<!--          <router-link class="menu-link" :to="{ name: 'ContributorApply' }">-->
<!--            <van-icon name="friends-o" />-->
<!--            <div class="menu-label">贡献者申请</div>-->
<!--          </router-link>-->
<!--        </div>-->

        <!--
        <div class="menu">
          <router-link class="menu-link not-online" to="">
            <div class="menu-label">消息动态</div>
            <van-icon name="comment-o" />
          </router-link>
        </div>
        <div class="menu">
          <router-link class="menu-link not-online" to="">
            <div class="menu-label">浏览历史</div>
            <van-icon name="todo-list-o" />
          </router-link>
        </div> -->
      </div>
      <div class="menu-card">
        <div class="menu">
          <router-link class="menu-link" :to="{ name: 'UserSetting' }">
            <van-icon name="setting-o" />
            <div class="menu-label">设置</div>
          </router-link>
        </div>
        <div class="menu">
          <div class="menu-link" @click="openOnlineService">
            <van-icon name="service-o" />
            <div class="menu-label">在线客服</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UserIndex',
}
</script>
<script setup>
import { Image, Icon } from 'vant'
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { openOnlineService } from '@/utils/common'
const VanImage = Image
const VanIcon = Icon
const store = useStore()

onMounted(() => {
  initData()
})

const user = ref({})
const player = ref({})
const playerExtend = ref({})
const showSettingDot = ref(false)

const initData = () => {
  store.dispatch('user/getInfo').then(res => {
    user.value = res.data.user
  })

  store.dispatch('player/getInfo').then(res => {
    player.value = res.data.player
    playerExtend.value = res.data.playerExtend
    if (!playerExtend.value || !playerExtend.value.mobile || !playerExtend.value.email) {
      showSettingDot.value = true
    }
  })
}
</script>

<style lang="scss" scoped>
.header {
  color: #fff;
  background: var(--app-main-bg-color);
  border-radius: 0 0 8px 8px;
  padding: 25px 18px;
}

.user-info {
  display: flex;
}

.user-info .avatar {
  margin-right: 10px;
  width: 55px;
  height: 55px;
  overflow: hidden;
  border-radius: 10px;
}

.user-info .nickname-box {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.user-info .nickname {
  // letter-spacing: 1.5px;
  font-size: 17px;
  font-weight: 500;
}

.user-info .steam-id {
  margin-top: 8px;
  color: #7c8092;
  // letter-spacing: 0.5px;
  font-weight: 300;
  font-size: 15px;
}

.content {
  background: var(--app-bg-color);
  box-sizing: border-box;
  color: var(--app-menu-color);
  display: flex;
  flex-flow: column;
  padding: 15px;
  top: 20px;
  border-radius: 10px 10px 0 0;
}

.menu-card {
  box-sizing: border-box;
  width: 100%;
  background: #2d2d34;
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  flex-flow: column;
  padding: 0 20px;
  height: auto;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu:after {
  font-family: 'vant-icon';
  content: '\e660';
  font-weight: 600;
  margin-left: 5px;
  width: 14px;
  height: 14px;
}

.menu-link {
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 15px 0;
}

.menu .van-icon {
  font-size: 18px;
  color: var(--app-active-color);
}

.menu-card .menu:not(:last-child) {
  border-bottom: 1px solid var(--app-border-bottom-color);
}

.menu-label {
  margin-left: 10px;
  font-size: 14px;
}

.menu-link {
  color: #b0b4c9;
}

.menu-link:active {
  color: rgba(176, 180, 201, 0.8);
}

.blue-btn {
  font-size: 14px;
  padding: 8px 10px;
}

.setting {
  color: #fff;
  position: absolute;
  right: 32px;
  top: 32px;
  font-size: 18px;
}
</style>
